import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import moment from "moment"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MicroBlogElement from "../components/microblog"

const MicroblogPost = ({ data, pageContext }) => {
  const { slug, next: nextMicroblog } = pageContext
  const { mdx } = data
  const { frontmatter, body } = mdx
  const {
    title,
    date,
    category = "uncategorized",
    progress = "work in progress",
    certainty = 5,
    importance = 5,
    page = false,
    excerpt = "",
  } = frontmatter

  return (
    <Layout>
      <SEO title={title} description={excerpt} />
      <div className="microblog-page-single">
        <div className="microblog-header">
          Microblog item – {moment(date).format("MMM DD, YYYY")}{" "}
          <div className="line"></div>
        </div>
        <div className="item">
          <MicroBlogElement content={mdx} />
        </div>

        <div className="microblog-next">
          Next <div className="line"></div>
        </div>
        <div className="item">
          <MicroBlogElement content={nextMicroblog} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date
        slug
        page
        category
      }
      body
      id
    }
  }
`

export default MicroblogPost
